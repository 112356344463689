import { Component, OnInit } from '@angular/core';
import { User } from '#models/user';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { UserService, ObsService } from '#services/api';
import { finalize } from 'rxjs';
import { AuthService } from '#services/shared';
import { EmailService } from '#services/api/email.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public users: User[];
  selectedPermissions: string[] = [];
  permissionNumber: number;
  directoryUsers: User[];
  CAI: string = '';
  FirstName: string = '';
  LastName: string = '';
  EmailAddress: string = '';
  userInput: string = '';
  selectedUser: User;
  userList: User[];
  isAdmin: boolean = false;
  isNew3rdParty: boolean = false;
  readyToSave: boolean = false;
  obses: any[];
  selectedObs: any;

  constructor(
    public loadingIndicatorSvc: LoadingIndicatorService,
    private messageSvc: MessageService,
    private emailSvc: EmailService,
    private userSvc: UserService,
    private authSvc: AuthService,
    private confirmationSvc: ConfirmationService,
    private obsSvc: ObsService,
    private translate: TranslateService
  ) {
    this.isAdmin = this.userSvc.isSystemAdmin();
  }

  ngOnInit() {
    this.refreshUserTable();
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      sticky: true,
      detail: e.error.Message || ''
    });
  }

  refreshObses() {
    this.obsSvc.getObsTimezones().subscribe(data => {
      this.obses = data;
    });
  }

  clearObsSelection() {
    this.selectedObs = '';
  }

  /**
   * Populates user table
   */
  refreshUserTable() {
    this.userSvc.getAll().subscribe({
      next:(data) => {
        this.userList = data;
        let i: number;
        for (i = 0; i < this.userList.length; i++) {
          this.adminCheck(this.userList[i]);
        }
      },
      error:(e) => {
        this.userList = [];
        this.showError(e, this.translate.instant('USERS.FailedUserList'));
      }
  });
  }

  /*
   * Checks to see if the user is a System Admin or not
   */
  adminCheck(user: User) {
    if (user.Permissions > 258) {
      user.sysAdmin = true;
    } else {
      user.sysAdmin = false;
    }
  }

  /**
   * Seaches for users in Azure Directory after every keystroke in search field
   * @param event Keystroke in the search field
   */
  searchDirectoryUsers(event) {
    this.userSvc.getUserFromDirectory(event.query).subscribe({
      next:(data: User[]) => {
        this.directoryUsers = data;
      },
      error:(e) => {
        this.directoryUsers = [];
        this.showError(e, this.translate.instant('USERS.FailedDirectoryUsers'));
      }
  });
  }

  /**
   * Auto-completes the rest of the form after a user from the dropdown is selected
   * @param event Dropdown selection
   */
  fillInfo(event) {
    this.selectedUser = event;
    this.CAI = this.selectedUser.Cai;
    this.FirstName = this.selectedUser.FirstName;
    this.LastName = this.selectedUser.LastName;
    this.EmailAddress = this.selectedUser.Email;
    this.readyToSave = true;
  }

  populateFromForm() {
    this.selectedUser = new User();
    this.selectedUser.FirstName = this.FirstName;
    this.selectedUser.LastName = this.LastName;
    this.selectedUser.Email = this.EmailAddress;

    this.readyToSave = true;
  }
  /**
   * Empties user's autofill info
   */
  emptyInfo() {
    this.CAI = '';
    this.FirstName = '';
    this.LastName = '';
    this.EmailAddress = '';
    this.readyToSave = false;
    this.selectedUser = new User();
    this.selectedObs = '';
  }
   /** Validate User  */
 
   
userExistsInUserList(a: User) {
  return this.userList.some(user => user.Email === a.Email)
}
     


  /**
   * Saves the user created to the database
   * Checks to ensure that user data has been filled out
   */
  saveUser(): void {
    this.loadingIndicatorSvc.show();

    if(this.isNew3rdParty){
      this.populateFromForm();
    }

    if ((this.selectedUser === undefined) || (this.selectedUser.Email.length <= 0 ) || (this.selectedUser.FirstName.length <= 0) || (this.selectedUser.LastName.length <= 0)) {
      this.messageSvc.add({
        severity: 'error',
        sticky: true,
        summary: this.translate.instant('USERS.MissingUserInformation'),
        detail: this.translate.instant('USERS.FillOutUser')
      });
      this.loadingIndicatorSvc.hide();
      return;
    }

    if (!this.readyToSave) {
      this.messageSvc.add({
        severity: 'error',
        sticky: true,
        summary: this.translate.instant('USERS.NoUserToSave'),
        detail: this.translate.instant('USERS.FillOutUser')
      });
      this.loadingIndicatorSvc.hide();
      return;
    }
    
   const isNotValid=this.userExistsInUserList(this.selectedUser)
   if (isNotValid)
    {
      this.loadingIndicatorSvc.hide();
      this.messageSvc.add({ severity: 'error', summary: this.translate.instant('USERS.ValidateUser'), detail: this.translate.instant('USERS.ValidateUser') });
      this.emptyInfo();
      this.refreshUserTable();
    }
   else if (!isNotValid)
    {
    this.selectedUser.Permissions = 3;
    this.userSvc
      .addUser(this.selectedUser).pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
        if(this.isNew3rdParty){
          this.sendEmailNotification()
        }
        this.emptyInfo();
      }))
      .subscribe({
        next:() => {
          this.messageSvc.add({ severity: 'success', summary: this.translate.instant('USERS.UserAdded'), detail: this.translate.instant('USERS.UserSaved') });
          this.refreshUserTable();
        },
        error:(e) => {
          this.showError(e, this.translate.instant('USERS.FailedNewUser'));
        }
       } );
      }
      this.loadingIndicatorSvc.hide();
  }

  sendEmailNotification(): void {
    this.loadingIndicatorSvc.show();


      this.emailSvc.newUserNotification(this.selectedUser).pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
      }))
      .subscribe({
        next:() => {
          this.messageSvc.add({ severity: 'success', summary: this.translate.instant('USERS.Notification'), detail: this.translate.instant('USERS.NotificationSent') });
        },
        error:(e) => {
          this.showError(e, this.translate.instant('USERS.FailedNotification'));
        }
  });

  }

  /**
   * Update user's admin rights, reverts changes if confirmation is not accepted
   * @param user the user who is being updated
   */
  updateUser(user: User): void {
    this.confirmationSvc.confirm({
      header: this.translate.instant('USERS.PleaseConfirm'),
      message: this.translate.instant('USERS.ConfirmAdmin') + `${user.FirstName + ' ' + user.LastName}?`,
      accept: () => {
        this.loadingIndicatorSvc.show();
        if (user.sysAdmin) {
          user.Permissions = 256;
        } else {
          user.Permissions = 1;
        }
        this.userSvc
          .updateUser(user).pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe({
            next:(data: any) => {
              user = data;
            },
            error:(e) => this.showError(e, this.translate.instant('USERS.FailedUpdate'))
      });
      },
      reject: () => {
        user.sysAdmin = !user.sysAdmin;
      }
    });
  }

  toggleUserStatus(user) {
    user.IsDeleted = !user.IsDeleted;
    this.userSvc.updateUser(user).subscribe({
      next:() => this.messageSvc.add({ severity: 'success', summary: this.translate.instant('USERS.UserUpdated') }),
      error:(e) => {
        this.showError(e, this.translate.instant('USERS.FailedUpdate'));
        user.IsDeleted = !user.IsDeleted;
      }
  });
  }

  toggleUserType() {
    this.emptyInfo();
  }
}
