import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { CrewAssignmentService, UserService } from '#services/api';
import { CrewAssignment, User } from '#models/index';
import { SelectItemGroup, MessageService, ConfirmationService } from 'primeng/api';
import { LoadingIndicatorService } from '#services/shared';
import { TranslateService } from '@ngx-translate/core';
import { finalize, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-assignment-grid',
  templateUrl: './assignmentgrid.component.html',
  styleUrls: ['./assignmentgrid.component.scss']
})
export class AssignmentGridComponent  {
  @ViewChildren('dv') dataView: QueryList<any>;

  private _assignments: any[] = [];
  private updateList: CrewAssignment[];
  selectedCrew = [];
  @Input()
  set assignments(assignments: CrewAssignment[]) {
    this._assignments = assignments ? assignments.filter(a => !a.IsDeleted) : [];

    this.showPaginator = this._assignments.length > this.pageSize;

    if (this.dataView?.first) {
      this.dataView.first.first = 0;
    }
  }
  get assignments(): CrewAssignment[] {
    return this._assignments;
  }

  @Input()
  crews: SelectItemGroup[];

  @Input()
  projectID: number;

  @Input()
  isProjectCompleted: boolean;


  showPaginator = false;
  pageSize = 50;

  filterValue = '';
  newUser: User;
  userList: User[];
  sortField = 'User.FirstName';
  sortOrder = 1;

  constructor(
    private messageSvc: MessageService,
    private confirmationSvc: ConfirmationService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private assignmentSvc: CrewAssignmentService,
    private userSvc: UserService,
    private translate: TranslateService
  ) {}



  private setEditMode(a: any, mode: boolean): void {
    if (mode === true) {
      a.backup = {
        ProjectId: a.ProjectId,
        IsDeleted: true,
        UserId: a.UserId,
        Crew: a.Crew,
        IsReadOnly: a.IsReadOnly
      };
    } else {
      a.backup = null;
    }
  }

  addUser(event, dataView) {
    this.filterValue = '';
    dataView.filter(this.filterValue);

    const newOne = this.assignmentSvc.createAssigntmentModel(event);
    if (this.crews.length > 0) {
      newOne.Crew = this.crews[0].items[0].value;
      newOne.ProjectId = this.projectID;
      newOne.IsDeleted = false;
      newOne.newItem = true;
      newOne.IsReadOnly = false;
    }

    this.setEditMode(newOne, true);

    this.assignments.unshift(newOne);

    this.newUser = null;
  }

  searchUsers(event) {
    this.userSvc.search(event.query).subscribe({
      next:(data) => this.userList = data,
      error:(e) => this.userList = []
    });
  }

  async validateSave(a: any) : Promise<boolean> {
    const isValid = !this._assignments
      .filter(e => e !== a)
      .some(e =>
        e.UserId === a.UserId
        // If other assignments are editable compare against backup, otherwise compare against normal Crew
        && ((e.backup && (e.backup.Crew.CrewId === a.Crew.Crewid)) || (e.Crew.CrewId === a.Crew.CrewId)));
    if(!isValid) {
      this.messageSvc.add({
        severity: 'error',
        summary: await firstValueFrom(this.translate.get('COMPONENTS.ValidationError')),
        detail: await firstValueFrom(this.translate.get('COMPONENTS.UserAssigned')),
        sticky: true,
      });
    }
    else{
      this.messageSvc.add({ severity: 'success', summary: 'Success', detail: 'Crew Saved...' });
    }
    return isValid;
  }

 async saveAssignment(a: any): Promise<void> {
    const isValidate = await this.validateSave(a);
    if(!isValidate || this.isProjectCompleted) {
      return;
    }
    else {
    const updates = [a];
    if(!a.newItem && a.backup.Crew.CrewId !== a.Crew.CrewId) {
      a.backup.IsDeleted = true;
      updates.push(a.backup);
    }

    this.loadingIndicatorSvc.show();
    this.assignmentSvc
      .updateAssignment(updates).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        data => {
          this.setEditMode(a, false);
          a.newItem = false;
        }
      );
      this.assignments.unshift(a);
    }
  }

  updateUserViewOnlyAccess(userAssignment: any): void {
    this.updateList = [userAssignment];
    this.confirmationSvc.confirm({
      header: this.translate.instant('USERS.PleaseConfirm'),
      message: (userAssignment.IsReadOnly ? this.translate.instant('USERS.TurnOnAccess') : this.translate.instant('USERS.TurnOffAccess')) + `${userAssignment.User.FirstName + ' ' + userAssignment.User.LastName}?`,
      accept: () => {
        let backup = JSON.parse(JSON.stringify(userAssignment));
        this.loadingIndicatorSvc.show();
          backup.IsReadOnly = !userAssignment.IsReadOnly;
          backup.IsDeleted = true;
          this.updateList.push(backup);
        this.assignmentSvc
        .updateAssignment(this.updateList).pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe(
            (data: any) => {
            });
      },
      reject: () => {
        userAssignment.IsReadOnly = !userAssignment.IsReadOnly;
      }
    });
  }

  enterEditMode(a: any): void {
    if(this.isProjectCompleted) {
      return;
    } else {
      this.setEditMode(a, true);
    }
  }

  exitEditMode(a: any): void {
    if(this.isProjectCompleted) {
      return;
    } else {
      if (!a.Crew) {
        const index = this.assignments.indexOf(a);
        if (index >= 0) {
          this.assignments.splice(index, 1);
        }
      } else {
        a.Crew = a.backup.Crew;
        this.setEditMode(a, false);
      }
    }
  }

  async deleteAssignment(a: CrewAssignment,dataView): Promise<void> {
    if(this.isProjectCompleted) {
      return;
    } else {
      this.confirmationSvc.confirm({
        header: await firstValueFrom(this.translate.get('COMPONENTS.PleaseConfirm')),
        message: await firstValueFrom(this.translate.get('COMPONENTS.RemoveAssignment', {firstName: a.User.FirstName, lastName: a.User.LastName})),
        accept: () => {
          this.loadingIndicatorSvc.show();
          a.IsDeleted = true;
          this.assignmentSvc
            .updateAssignment([a]).pipe(
            finalize(() => {
              this.loadingIndicatorSvc.hide();
            }))
            .subscribe(
              () => {
                this.filterValue = '';
                dataView.filter(this.filterValue);
                const index = this.assignments.indexOf(a);
                this.assignments.splice(index, 1);
              }
            );
        }
      });
    }
  }

  sort(sortField: string): void {
    if(sortField === this.sortField) {
      this.sortOrder = this.sortOrder * -1;
    } else {
      this.sortField = sortField;
    }
  }
visible: boolean = false;
event
fname
lname
uemail
ucai
  addUsers(event){
    this.event = event;
    this.fname = event.FirstName;
    this.lname = event.LastName;
    this.uemail = event.Email;
    this.ucai = event.Cai;
    this.visible = true;
    this.newUser = null;
  }

  saveCrew(dataView){
    if(this.selectedCrew.length > 0){
      this.filterValue = '';
      dataView.filter(this.filterValue);
      for(let i of this.selectedCrew){
        let newOne = this.assignmentSvc.createAssigntmentModel(this.event);
        newOne.Crew = i.value;
        newOne.ProjectId = this.projectID;
        newOne.IsDeleted = false;
        newOne.newItem = true;
        newOne.IsReadOnly = false;
        this.saveAssignment(newOne);
      }
         this.visible = false;
         this.selectedCrew = [];
    }
    else{
          this.messageSvc.add({ severity: 'error', summary: 'Error', detail: 'No Crew Selected...' });
    }
 }
}
